import {closeModal, openModal} from "../components/modal";

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

$('.form__input').on('change', function () {
  $('.form__input--error').hide()
})

$(function () {
  $('#applicationForm').submit(function (e) {
    e.preventDefault()
    const $form = $(this)

    if (!$('#name').val()) {
      $('#nameError').show()
    } else {
      $('#nameError').hide()
      if (!$('#last_name').val()) {
        $('#lastNameError').show()
      } else {
        $('#lastNameError').hide()
        if (!$('#organization').val()) {
          $('#organizationError').show()
        } else {
          $('#organizationError').hide()
          if (!$('#association_number').val()){
            $('#associationNumberError').show()
          } else {
            $('#associationNumberError').hide()
            if (!$('#street').val()){
              $('#streetError').show()
            } else {
              $('#streetError').hide()
              if(!$('#postcode').val()){
                $('#postcodeError').show()
              } else {
                $('#postcodeError').hide()
                if (!$('#phone').val()){
                  $('#phoneError').show()
                } else {
                  $('#phoneError').hide()
                  if (!validateEmail($('#email').val())){
                    $('#emailError').show()
                  } else {
                    $('#emailError').hide()
                    if (!$('#donation').val()){
                      $('#donationError').show()
                    } else {
                      $('#donationError').hide()
                      if (!$('#purpose').val()){
                        $('#purposeError').show()
                      } else {
                        let formData = new FormData(document.querySelector("form"));
                        fetch('https://dev.laurus.agency/ernstprost/formRequest',
                          {
                            method: "POST",
                            body: formData,
                          }
                        )
                          .then((res) => res.json())
                          .then((res) => {
                            if (!res.success)
                              throw res
                            $form[0].reset()
                            // alert("Success")
                            $('.form-close-icon').trigger('click')
                            openModal('success')
                          })
                          .catch(function (err) {
                            console.error(err)
                            // if (err.hasOwnProperty("error_codes"))
                            //   err['error_codes'].forEach(e => addError(e, $form))
                          })
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  })
})

let input = document.getElementById('file');
let infoArea = document.getElementById('fileName');

input.onchange = function () {
  checkSize()
  showFileName()
}

function showFileName() {
  infoArea.textContent = input.files[0].name;
}

function checkSize() {
  if (input.files[0].size > 15728640) {
    alert('Max size is 15mb!')
    input.value = ''
  }
}

$('.form-close-icon').on('click', function (e) {
  e.preventDefault()
  closeModal()
})

// if (!validateContactForm($form))
//   return